import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { motion } from 'framer-motion'

import loadable from '@loadable/component'
import { useHeaderNav } from '../../stores/HeaderNav'
import { useMenuItems } from './MenuItems'

const PhoneNumber = loadable(() => import('./PhoneNumber'))

const HeaderNav = ({ location, phoneNumber }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const { menuVisible } = useHeaderNav()

  const getMenuItems = useMenuItems()

  const headerData = language === 'en' ? getMenuItems?.globalHeaderEn : getMenuItems?.globalHeaderIt  

  const { items } = headerData.menu

  return (
    <div className="flex ml-auto">
      <div className="siteNav">
        <ul className="flex mr-4">
          {items.map((page, index) => {
            let link = page.url
            if (link) {
              if (!page.url.endsWith('/')) {
                link = `${page.url}/`
              }
            }

            return (
              <li className="block px-5" key={index}>
                <Link
                  className={`block py-6 text-14 ${
                    location.pathname.startsWith(link)
                      ? 'border-b-2 border-white'
                      : ''
                  }`}
                  to={link}
                >
                  {page.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>

      <motion.div
        className="flex items-center text-right siteInfo"
        animate={{
          opacity: menuVisible ? 0 : 1,
        }}
      >
        <PhoneNumber phoneNumber={phoneNumber} />
      </motion.div>
    </div>
  )
}

HeaderNav.propTypes = {
  location: PropTypes.object,
  phoneNumber: PropTypes.string,
}
HeaderNav.defaultProps = {
  location: {},
  phoneNumber: '',
}

export default HeaderNav
